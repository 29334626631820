(function () {
  'use strict';

  angular
    .module('dashboard.learning')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('dashboard.learning', {
        url: '/learning',
        templateUrl: 'dashboard/learning/learning.tpl.html',
        controller: 'LearningCtrl',
        controllerAs: 'learning',
        roles: ['superadmin', 'admin'],
        abstract: true
      });
  }
}());
